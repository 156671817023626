import { getToken, onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { sendDeviceTokenService } from 'services/fcm';
import { messaging } from '../config/firebase';
import { CH_INSTALLATION, NOTICE_TYPE } from '../constants';
import useBadge from './useBadge';
import useCurrentBuilding from './useCurrentBuilding';

const NOTICES = (notifyData) => ({
  MSG_NOTICE: `/buildings/${notifyData.unitGroup}/resident-communicate`,
  HELPDESK_NOTICE: `/buildings/${notifyData.unitGroup}/help-desk`,
  BOOKING_NOTICE: `/buildings/${notifyData.unitGroup}/amenities-orders`,
  VISITOR_NOTICE: `/buildings/${notifyData.unitGroup}/access-management`,
  PAYMENT_NOTICE: `/buildings/${notifyData.unitGroup}/statistics/receipt`,
  REQUEST_NOTICE: `/buildings/${notifyData.unitGroup}/request`,
  SYNC_FEE_NOTICE_TO_FAST: `/buildings/${notifyData.unitGroup}/integrate-accounting`,
  SYNC_RECEIPT_TO_FAST: `/buildings/${notifyData.unitGroup}/integrate-accounting`,
  UTILITY_BOOKING_NOTICE: `/buildings/${notifyData.unitGroup}/amenities-orders`,
});

export default function useFcm() {
  const { getHelpDeskBadges, getChatBadges } = useBadge();
  const { currentBuilding } = useCurrentBuilding();

  const sendDeviceTokenToServer = async (currentToken) => {
    localStorage.setItem(CH_INSTALLATION, currentToken);
    await sendDeviceTokenService(currentToken, 'browser');
  };

  const handleCallback = () => {
    onMessage(messaging, ({ data }) => {
      const now = new Date();
      const { title, body, type } = data;
      const notificationTitle = title;
      const notificationOptions = {
        body,
        icon: '/assets/images/lavida_favicon.ico',
        renotify: true,
        tag: type + now.getTime(),
      };
      switch (type) {
        case NOTICE_TYPE.MSG:
          getChatBadges(currentBuilding?.id);
          break;
        case NOTICE_TYPE.HELP_DESK:
          getHelpDeskBadges(currentBuilding?.id);
          break;
        case NOTICE_TYPE.REQUEST:
          break;
        case NOTICE_TYPE.UTILITY_BOOKING:
          break;
        case NOTICE_TYPE.VISITOR:
          break;
        case NOTICE_TYPE.PAYMENT:
          break;
        default:
          break;
      }
      const notification = new Notification(notificationTitle, notificationOptions);
      notification.onclick = (event) => {
        event.preventDefault();
        if (data && data.unitGroup) {
          const urlToOpen = new URL(NOTICES(data)[data.type], window.location.origin).href;
          window.open(urlToOpen);
        }
      };
    });
  };

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: process.env.FCM_KEY_PAIR });
      await sendDeviceTokenToServer(token);
      handleCallback();
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  return messaging;
}
