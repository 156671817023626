export const MODULE_APARTMENT = {
  READ: 'APARTMENT_READ',
  EXPORT: 'APARTMENT_EXPORT',
};

export const MODULE_APARTMENT_DETAIL = {
  READ: 'APARTMENT_DETAIL_READ',
  SERVICE_HISTORY_READ: 'APARTMENT_DETAIL_SERVICE_HISTORY_READ',
  TRANSACTION_HISTORY_READ: 'APARTMENT_DETAIL_TRANSACTION_HISTORY_READ',
};

export const MODULE_RESIDENT = {
  READ: 'RESIDENT_READ',
  WRITE: 'RESIDENT_WRITE',
  EXPORT: 'RESIDENT_EXPORT',
};

export const MODULE_SERVICE = {
  SERVICE_READ: 'SERVICE_READ',
  SERVICE_WRITE: 'SERVICE_WRITE',
  SERVICE_EXPORT: 'SERVICE_EXPORT',
};

export const MODULE_AMENITIES = {
  AMENITY_BOOKING_READ: 'AMENITY_BOOKING_READ',
  AMENITY_BOOKING_APPROVE: 'AMENITY_BOOKING_APPROVE',
};

export const MODULE_BILL = {
  READ: 'INVOICE_READ',
  WRITE: 'INVOICE_WRITE',
  EXPORT: 'REPORT_SERVICE_CHARGE_EXPORT',
};

export const MODULE_INVOICE = {
  READ: 'INVOICE_READ',
  WRITE: 'INVOICE_WRITE',
  SEND: 'INVOICE_SEND',
};

export const MODULE_PAYMENT = {
  READ: 'PAYMENT_READ',
  WRITE: 'PAYMENT_WRITE',
};

export const MODULE_CONFIG_BUILDING = {
  READ: 'BUILDING_READ',
  WRITE: 'BUILDING_WRITE',
  DASHBOARD_READ: 'BUILDING_DASHBOARD_READ',
};

export const MODULE_CONFIG_TARIFF = {
  READ: 'CONTRACT_TEMPLATE_READ',
  WRITE: 'CONTRACT_TEMPLATE_WRITE',
};

export const MODULE_CONFIG_UNIT = {
  UNIT_READ: 'CONFIG_UNIT_READ',
  UNIT_WRITE: 'CONFIG_UNIT_WRITE',
  UNIT_EXPORT: 'CONFIG_UNIT_EXPORT',
};

export const MODULE_CONFIG_SERVICE = {
  SERVICE_WRITE: 'CONFIG_SERVICE_WRITE',
  SERVICE_READ: 'CONFIG_SERVICE_READ',
};

export const MODULE_CONFIG_AMENITIES = {
  AMENITIES_READ: 'CONFIG_AMENITY_READ',
  AMENITIES_WRITE: 'CONFIG_AMENITY_WRITE',
};

export const MODULE_CONFIG_FUND = {
  FUND_WRITE: 'CONFIG_FUND_WRITE',
  FUND_READ: 'CONFIG_FUND_READ',
};

export const MODULE_CONFIG_LABEL = {
  LABEL_WRITE: 'CONFIG_LABEL_WRITE',
  LABEL_READ: 'CONFIG_LABEL_READ',
};

export const MODULE_ACCOUNTING_DATA_LOCK = {
  LOCK_DATA_READ: 'LOCK_DATA_READ',
  LOCK_DATA_WRITE: 'LOCK_DATA_WRITE',
  LOCK_DATA_UPDATE: 'LOCK_DATA_UPDATE',
  LOCK_DATA_HISTORY_READ: 'LOCK_DATA_HISTORY_READ',
};

export const NOT_NEED_PERMISSION = 'NOT_NEED_PERMISSION';

export const MODULE_CONFIG_TEMPLATE = {
  TEMPLATE_WRITE: 'CONFIG_TEMPLATE_WRITE',
  TEMPLATE_READ: 'CONFIG_TEMPLATE_READ',
};

export const MODULE_STATISTIC_CASHBOOK = {
  READ: 'REPORT_CASHBOOK_READ',
  EXPORT: 'REPORT_CASHBOOK_EXPORT',
};

export const MODULE_STATISTIC_RECEIPT = {
  READ: 'REPORT_RECEIPT_READ',
  MANUAL_CREATE: 'REPORT_RECEIPT_MANUAL_CREATE',
  WRITE: 'REPORT_RECEIPT_WRITE',
  EXPORT: 'REPORT_RECEIPT_EXPORT',
};

export const MODULE_STATISTIC_PAYSLIP = {
  READ: 'REPORT_PAYSLIP_READ',
  WRITE: 'REPORT_PAYSLIP_WRITE',
  EXPORT: 'REPORT_PAYSLIP_EXPORT',
  MANUAL_CREATE: 'REPORT_PAYSLIP_MANUAL_CREATE',
};

export const MODULE_STATISTIC_APARTMENT_ACCOUNT = {
  READ: 'REPORT_APARTMENT_ACCOUNT_READ',
  EXPORT: 'REPORT_APARTMENT_ACCOUNT_EXPORT',
};

export const MODULE_STATISTIC_FINANCIAL = {
  READ: 'REPORT_CASHBOOK_READ',
  EXPORT: 'REPORT_CASHBOOK_EXPORT',
};

export const MODULE_VISITOR = {
  VISITOR_READ: 'VISITOR_READ',
  VISITOR_ACTION: 'VISITOR_ACTION',
};

export const MODULE_STAFF = {
  READ: 'STAFF_READ',
  WRITE: 'STAFF_WRITE',
  EXPORT: 'STAFF_EXPORT',
};

export const MODULE_REQUEST = {
  READ: 'REQUEST_READ',
  WRITE: 'REQUEST_WRITE',
  EXPORT: 'REQUEST_EXPORT',
};

export const MODULE_ANNOUNCEMENT = {
  READ: 'COMMUNICATE_ANNOUNCEMENT_READ',
  WRITE: 'COMMUNICATE_ANNOUNCEMENT_WRITE',
};

export const MODULE_HELP_DESK = {
  READ: 'HELPDESK_READ',
  APPROVE: 'HELPDESK_APPROVE',
  REPLAY: 'HELPDESK_REPLY',
};

export const MODULE_CHAT = {
  READ_ALL: 'COMMUNICATE_CHAT_READ_ALL',
  READ_DEPARTMENT: 'COMMUNICATE_CHAT_READ_DEPARTMENT',
  WRITE_ALL: 'COMMUNICATE_CHAT_WRITE_ALL',
  WRITE_DEPARTMENT: 'COMMUNICATE_CHAT_WRITE_DEPARTMENT',
};

export const MODULE_CARD = {
  READ: 'CARD_READ',
  WRITE: 'CARD_WRITE',
  EXPORT: 'CARD_EXPORT',
};

export const MODULE_REGISTRATION_FORM_CARD = {
  READ: 'CARD_REGISTRATION_FORM_READ',
  WRITE: 'CARD_REGISTRATION_FORM_WRITE',
  EXPORT: 'CARD_REGISTRATION_FORM_EXPORT',
};

export const MODULE_DOCUMENT = {
  READ: 'DOCUMENT_READ',
  WRITE: 'DOCUMENT_WRITE',
};

export const MODULE_INTEGRATE_ACCOUNTING = {
  READ: 'NOT_NEED_PERMISSION',
  WRITE: 'NOT_NEED_PERMISSION',
};

export const MODULE_ASSET = {
  READ: 'ASSET_READ',
  WRITE: 'ASSET_WRITE',
  EXPORT: 'ASSET_EXPORT',
};

export const MODULE_SUPPLY = {
  READ: 'SUPPLY_READ',
  WRITE: 'SUPPLY_WRITE',
  EXPORT: 'ASSET_EXPORT',
};

export const MODULE_PARTNER = {
  READ: 'PARTNER_READ',
  WRITE: 'PARTNER_WRITE',
  EXPORT: 'ASSET_EXPORT',
};

export const MODULE_LOG_ACTIVITY = {
  READ: 'LOG_ACTIVITY_READ_ALL',
};

export const permissions = {
  APARTMENT: Object.values(MODULE_APARTMENT),
  APARTMENT_DETAIL: Object.values(MODULE_APARTMENT_DETAIL),
  RESIDENT: Object.values(MODULE_RESIDENT),
  SERVICE: Object.values(MODULE_SERVICE),
  AMENITIES: Object.values(MODULE_AMENITIES),
  AMENITIES_MERCHANT: Object.values(MODULE_AMENITIES),
  BILL: Object.values(MODULE_BILL),
  INVOICE: Object.values(MODULE_INVOICE),
  PAYMENT: Object.values(MODULE_PAYMENT),
  CONFIG_BUILDING: Object.values(MODULE_CONFIG_BUILDING),
  ACCOUNTING_DATA_LOCK: Object.values(MODULE_ACCOUNTING_DATA_LOCK),
  CONFIG_UNIT: Object.values(MODULE_CONFIG_UNIT),
  CONFIG_TARIFF: Object.values(MODULE_CONFIG_TARIFF),
  CONFIG_SERVICE: Object.values(MODULE_CONFIG_SERVICE),
  CONFIG_AMENITIES: Object.values(MODULE_CONFIG_AMENITIES),
  CONFIG_FUND: Object.values(MODULE_CONFIG_FUND),
  CONFIG_LABEL: Object.values(MODULE_CONFIG_LABEL),
  STATISTIC_CASHBOOK: Object.values(MODULE_STATISTIC_CASHBOOK),
  STATISTIC_RECEIPT: Object.values(MODULE_STATISTIC_RECEIPT),
  STATISTIC_PAYSLIP: Object.values(MODULE_STATISTIC_PAYSLIP),
  STATISTIC_APARTMENT_ACCOUNT: Object.values(MODULE_STATISTIC_APARTMENT_ACCOUNT),
  VISITOR: Object.values(MODULE_VISITOR),
  STAFF: Object.values(MODULE_STAFF),
  REQUEST: Object.values(MODULE_REQUEST),
  STATISTIC_FINANCIAL: Object.values(MODULE_STATISTIC_FINANCIAL),
  ANNOUNCEMENT: Object.values(MODULE_ANNOUNCEMENT),
  HELP_DESK: Object.values(MODULE_HELP_DESK),
  CHAT: Object.values(MODULE_CHAT),
  CARD: Object.values(MODULE_CARD),
  REGISTRATION_FORM_CARD: Object.values(MODULE_REGISTRATION_FORM_CARD),
  DOCUMENT: Object.values(MODULE_DOCUMENT),
  INTEGRATE_ACCOUNTING: Object.values(MODULE_INTEGRATE_ACCOUNTING),
  ASSET: Object.values(MODULE_ASSET),
  SUPPLY: Object.values(MODULE_SUPPLY),
  PARTNER: Object.values(MODULE_PARTNER),
  LOG_ACTIVITY: Object.values(MODULE_LOG_ACTIVITY),
  NOT_NEED_PERMISSION,
};
