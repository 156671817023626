import { useContext } from 'react';
import { Context } from 'globalContext';
import { getTotalRequestExpiredService } from 'services/request';
import { countHelpDeskBadgeService } from 'services/helpDesk';
import { countChatBadgesService } from 'services/chat';
import { countBadgesAmenitiesBookingService } from 'services/amenities';

const useBadge = () => {
  const { badges, setBadges } = useContext(Context);

  const getBadges = async (idBuilding) => {
    const [requestBadge, helpDeskBadge, chatBadges, amenitiesBadges] = await Promise.all([
      getTotalRequestExpiredService(),
      countHelpDeskBadgeService(idBuilding),
      countChatBadgesService(idBuilding),
      countBadgesAmenitiesBookingService(idBuilding),
    ]);

    const requestBadgeOfBuilding = requestBadge.data.find((badge) => badge.unitGroupId === +idBuilding);

    setBadges({
      request: requestBadgeOfBuilding?.overDue ?? 0,
      helpDesk: helpDeskBadge?.data?.badge ?? 0,
      chat: chatBadges?.data?.badge ?? 0,
      amenities: amenitiesBadges?.data ?? 0,
    });
  };

  const getHelpDeskBadges = async (idBuilding) => {
    if (badges.helpDesk === 0) {
      const result = await countHelpDeskBadgeService(idBuilding);
      setBadges({
        ...badges,
        helpDesk: result?.data?.badge ?? 0,
      });
    }
  };

  const getChatBadges = async (idBuilding) => {
    if (badges.chat === 0) {
      const chatBadges = await countChatBadgesService(idBuilding);
      setBadges({
        ...badges,
        chat: chatBadges?.data?.badge ?? 0,
      });
    }
  };

  return { badges, getBadges, getHelpDeskBadges, getChatBadges };
};

export default useBadge;
