const fontSizeMedium = 14;
const textColor = 'rgba(37, 37, 37)';

const theme = {
  // color
  color: {
    primary: '#0530AD',
    link: '#0530AD',
    success: '#87d068',
    warning: '#f50',
    error: '#f63b4b',
  },
  // text
  textColor,
  textSecondaryColor: 'rgba(37, 37, 37, 0.85)',
  textColorInverse: '#ffffff',
  disabledColor: 'rgba(37, 37, 37, 37.45)',
  fontWeight: {
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
  fontSize: {
    xs: 8,
    sm: 12,
    md: 14,
    lg: 16,
  },
  heading: {
    one: Math.ceil(fontSizeMedium * 2.71),
    two: Math.ceil(fontSizeMedium * 2.14),
    three: Math.ceil(fontSizeMedium * 1.71),
    four: Math.ceil(fontSizeMedium * 1.42),
    five: Math.ceil(fontSizeMedium * 1.14),
  },
  lintHeight: 1.5715,
  // border
  borderColor: '#ddd',
  borderRadius: 4,
  padding: {
    xss: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
  },
  margin: {
    xss: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
  },
  // sidebar
  backgroundSidebar: '#112758',
  backgroundSidebarSecondary: '#0F224D',
  backgroundItemActiveSidebar: '#039be5',
  // navbar
  colorNavbar: textColor,
  backgroundNavbar: '#f5f5f5',
  // content
  backgroundContent: '#d6d6d6',
  labelColor: '#7F8D9E',
  iconSize: 19,
  offsetScrollX: -10,
};

export default theme;
